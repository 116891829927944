<template>
  <div id="app">
    <the-nav-bar />
    <!-- <transition name="fade"> -->
    <router-view />
    <!-- </transition> -->
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';

export default {
  name: 'App',
  components: {
    TheNavBar
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fadeUp-enter-active,
.fadeUp-leave-active {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.5s;
  transition-property: opacity translate3d;
  /* transition-timing-function: ease-in; */
}

.fadeUp-enter,
.fadeUp-leave-active {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}
</style>
