<template>
  <nav
    class="navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <span class="brand has-text-black">
            mhackyu.dev
          </span>
        </router-link>

        <a
          role="button"
          class="navbar-burger burger"
          :class="{ 'is-active': isShowMenu }"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
          @blur="hideMenu"
          @click="isShowMenu = !isShowMenu"
          tabindex="0"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': isShowMenu }">
        <div class="navbar-start">
          <a @mousedown="pushTo('/projects')" class="navbar-item">
            projects
          </a>

          <a @mousedown="pushTo('/about')" class="navbar-item">
            about
          </a>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <a
              @mousedown="
                redirectTo(
                  'https://www.linkedin.com/in/mark-christian-paderes-97782b166/'
                )
              "
              class="button"
              target="_blank"
              rel="noreferrer"
              ><span class="icon"> <i class="fab fa-linkedin fa-lg"></i> </span
            ></a>
            <a
              @mousedown="redirectTo('https://github.com/mhackyu')"
              class="button"
              target="_blank"
              rel="noreferrer"
              ><span class="icon"> <i class="fab fa-github fa-lg"></i> </span
            ></a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TheNavBar',
  data() {
    return {
      isShowMenu: false
    };
  },
  methods: {
    hideMenu() {
      this.isShowMenu = false;
    },
    redirectTo(url) {
      window.location.href = url;
    },
    pushTo(url) {
      if (this.$route.path !== url) this.$router.push(url);
    }
  }
};
</script>

<style>
span.brand {
  /* color: #2a3e4c; */
  font-size: 1.5rem;
  font-weight: 800;
}
</style>
